<template>
  <div>
    <div class="row">
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <!-- Filter -->
        <div class="row justify-content-between mt-n3">
          <div class="col-md-2">
            <b-button
              squared
              @click="$router.push('/patient/themplate-print')"
              variant="success"
            >Cetak Blangko</b-button>
          </div>
          <div class="col-md-7">
            <span class="d-none d-sm-block">
                  <b-input-group>
                    <template #prepend>
                      <b-button
                        squared
                        @click="resetAllFilter"
                        variant="danger"
                        >Reset</b-button
                      >
                      <b-button
                        squared
                        @click="pagination"
                        variant="success"
                        >Cari</b-button
                      >
                    </template>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Tanggal Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Tanggal Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <template #prepend>
                          <b-button
                            squared
                            @click="resetAllFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                          <b-button
                            squared
                            @click="pagination"
                            variant="success"
                            >Cari</b-button
                          >
                        </template>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Tanggal Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Tanggal Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
          </div>
          <div class="col-md-3 col-12">
            <b-input-group>
              <template #prepend>
                <b-input-group-text squared>
                  <b-icon-search></b-icon-search>
                </b-input-group-text>
              </template>
              <b-form-input
                id="input-trigger-modal-patient"
                v-model="display.patient_name"
                placeholder="Pilih Pasien"
                readonly
                @click="$bvModal.show('modal-patient')"
              >
              </b-form-input>
            </b-input-group>
          </div>
        </div>
      </b-alert>
    </div>

    <div
      class="row"
      v-if="dataLoad == true"
    >

      <div class="col-12">
        <b-button
          squared
          class="mx-1"
          size="sm"
          variant="primary"
          @click="btnPrintOnClick"
        >Print</b-button>
        <b-button
          squared
          :class="'mx-2'"
          size="sm"
          variant="info"
          @click="btnDownloadOnClick"
        >Download</b-button>
      </div>

      <Print
        :data="data"
        :items="items"
      />

      <!-- PDF -->
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :filename="'Blanko Rekam Medis -' + display.patient_name"
        :pdf-quality="1"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <Print
            :data="data"
            :items="items"
            v-if="dataLoad == true"
          />
        </section>
      </vue-html2pdf>

    </div>
    <Modal
      @chosenPatient="setPatient"
      @submitedPatient="setPatient"
    />
  </div>

</template>

<script>

import Print from '@/component/patients/PrintMedicalRecord.vue'
import VueHtml2pdf from "vue-html2pdf"
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import Modal from "@/component/general/ModalPatient.vue";
export default {

  components: {
    Print,
    VueHtml2pdf,
    Modal
  },

  data() {
    return {
      // Data
      data: {},
      items: [],
      dataLoad: false,
      filter: {
        start_date: "",
        end_date: "",
        id: null
      },
      display: {
        patient_name: null
      }

    }
  },

  methods: {

    // filter
    resetBirtDate() {
      this.filter.birt_date = "";
      this.pagination();
    },

    resetAllFilter() { 
      this.filter = validation.clearForm(this.filter)
      this.display.patient_name = ''
      this.pagination()
    },

    setPatient(value) {
      this.filter.id = value.id;
      this.display.patient_name = value.name;
      this.$bvModal.hide("modal-patient");

    },
    generalFilter(sended) {
      this.filter.start_date = sended.start_date
      this.filter.end_date = sended.end_date
    },

    async pagination() {
      let filterParams = `start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate('medical-record-reports/' + this.filter.id, `?${filterParams}`)
      this.items = response.data
      this.getPatient()
      this.dataLoad = true
    },

    async getPatient() {
      let response = await module.get('patients/' + this.filter.id)
      this.data = response
    },

    btnPrintOnClick() {
      this.$htmlToPaper('print')
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cetak Rekam Medis" },
      { title: "Daftar Cetak Rekam Medis", route: "/patients/case" },
      { title: "Print" },
    ])
  },

}
</script>

<style>
</style>